<template>
  <div class="contain">
    <div class="login-container">
      <div class="login-form">
        <h2>登录</h2>
        <form @submit.prevent="login" class="login-form2">
          <div class="form-group">
            <label for="phone" class="login-lable">账号：</label>
            <input class="input-phone"  id="phone" v-model="account" placeholder="请输入手机号/邮箱" required/>
          </div>
          <div class="form-group">
            <label for="phone" class="login-lable">密码：</label>
            <input class="input-phone"  id="password" type="password" v-model="password" placeholder="请输入密码" required/>
          </div>
          <div class="form-fp">
            <a href="#" class="forgot-password">忘记密码</a>
          </div>
          <button class="login-button" type="submit">登录</button>
        </form>
        <div class="register">
          没有账号？<router-link to="/Register">立即注册</router-link>
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  export default {
    name: 'Login',
    data() {
      return {
        account: "",
        password: "",
        name:"",
        userid:"",
      };
    },
    methods: {
      //判断是邮箱还是手机号
      isEmailOrPhone(input) {
      const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/;//eslint-disable-line
      const phoneRegex = /^1[0-9]{10}$/;
      if (emailRegex.test(input)) {
        return 'email';
      } else if (phoneRegex.test(input)) {
        return 'phone';
      } else {
        return 'unknown';
      }
    },
    login() {
      //提交逻辑
      if(this.account == 'admin'&& this.password == 'admin123'){
        this.name = 'admin';
        alert("登录成功！")
        localStorage.setItem('name', JSON.stringify('admin'));
        localStorage.setItem('type', 2);
        window.location.replace('/home')
      }
      else{
        const accountType = this.isEmailOrPhone(this.account);
      if(accountType=='email'){
        this.$axios.post('/login-email', this.$qs.stringify({
        email: this.account,
        password: this.password
      })).then(response => {
        console.log(response.data.data);
        if(response.data.code==-1){
          alert(response.data.message)
        }else{
          this.name = response.data.data.name;
          alert("登录成功！")
         //传参：将用户名传到本地缓存中
          localStorage.setItem('name', JSON.stringify(response.data.data.name))
          localStorage.setItem('userid', response.data.data.id);
          localStorage.setItem('phone', JSON.stringify(response.data.data.phone));
          localStorage.setItem('email', JSON.stringify(response.data.data.email));
          localStorage.setItem('school', JSON.stringify(response.data.data.school));
          localStorage.setItem('type', response.data.data.type);
          window.location.replace('/home')
        }
      }).catch(error => {
        console.log(error);
        alert('用户名或密码错误！');
      })
      }
      else if(accountType=='phone'){
        this.$axios.post('/login-phone', this.$qs.stringify({
        phone: this.account,
        password: this.password
      })).then(response => {
        console.log(response.data.data);
        if(response.data.code==-1){
          alert(response.data.message)
        }else{
          this.name = response.data.data.name;
          alert("登录成功！")
         //传参：将用户名传到本地缓存中
         console.log(response.data.data)
          localStorage.setItem('name', JSON.stringify(response.data.data.name))
          localStorage.setItem('userid', response.data.data.id);
          localStorage.setItem('phone', JSON.stringify(response.data.data.phone));
          localStorage.setItem('email', JSON.stringify(response.data.data.email));
          localStorage.setItem('school', JSON.stringify(response.data.data.school));
          localStorage.setItem('type', response.data.data.type);
          window.location.replace('/home')
        }
      }).catch(error => {
        console.log(error);
        alert('用户名或密码错误！!');
      })
      }
      else{
        alert('用户名或密码错误！');
      }
    }
      }
      
    },
  };
  </script>
  
  <style>
  .login-container {
    float: right;
    display: flex;
    justify-content: right;
    align-items: center;
    justify-items: center;
    margin-top: 15vh;
    margin-right: 5vw;
  }
  
  .login-form {
    width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: rgba(255,255,255,0.9);
    box-shadow: rgb(132, 131, 131) 0 0px 20px -1px;
    -webkit-box-shadow: rgb(132, 131, 131) 0 0px 20px -1px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
  }
  .login-form2 {
    background-color: rgba(255,255,255,0);
    box-shadow: none;
    max-width: 500px;
  }
  
  h2 {
    margin-top: 0;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 1rem;
    width: 100%;
  }
  .form-fp {
    float: right;
    margin-bottom: 1rem;
    width: 300px;
  }
  
  .login-lable{
    display: inline-block;
    text-align: left;
    width: auto;
    margin-bottom: 5px;
  }
  
  .input-phone {
    display: inline-block;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-bottom: 0px;
    width: 300px;
  }
  
  button {
    display: inline-block;
    width: 100px;
    margin-left: 10px;
    padding: 5px;
    border: none;
    border-radius: 3px;
    background-color: #0077c2;
    color: #fff;
    cursor: pointer;
  }
  
  button:disabled {
    opacity:0.5;
cursor: not-allowed;
}

input[type="checkbox"] {
margin-right: 5px;
vertical-align: middle;
}

.forgot-password {
float: right;
font-size: 12px;
color: #0077c2;
}

.login-button {
display: block;
width: 30%;
margin-top: 20px;
padding: 10px;
border: none;
border-radius: 3px;
background-color: #0077c2;
color: #fff;
cursor: pointer;
}

.register {
margin-top: 20px;
text-align: center;
}

.register a {
color: #0077c2;
}
</style>
  