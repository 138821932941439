process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import axios from 'axios'
import qs from 'qs'
import COS from 'cos-js-sdk-v5'
import VuePreview from 'vue-preview'
//设置反向代理，前端请求默认发送到
axios.defaults.baseURL = 'https://api.xhpolaris.com'
// axios.defaults.baseURL = 'http://aigc-back.xhpolaris.com/'
// axios.defaults.baseURL = 'http://localhost:8087/'
axios.defaults.withCredentials = true
const app = createApp(App)
app.use(router)
app.use(ElementPlus)
//全局注册，之后可在其他组件中通过 this.$axios 发送数据
app.config.globalProperties.$axios = axios
app.config.globalProperties.$qs = qs
app.config.globalProperties.$cos = new COS({
    SecretId: 'AKIDGPK4cx74q9QXRVu787wIaqQt8C7qTwBY',
    SecretKey: 'JXiHmATW7XksyseBd5jGpKb0bAar7HQb',
    Bucket: 'aigc-static-1308722423',
    Region: 'ap-shanghai'
  })

const previewOptions = {
  fullscreenEl: false,
  zoomEl: false,
  shareEl: false,
  bgOpacity: 0.8,
  loop: false,
  pinchToClose: true,
  closeOnVerticalDrag: false,
  closeOnScroll: false
}
  
app.use(VuePreview, previewOptions)

app.mount('#app')




