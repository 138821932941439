<!-- Home.vue -->
<template>
  <div class="home-container">
    <div class="headline-container">
      <div>
        <img alt="logo" src="../assets/logo.png">
      </div>
      <div class="headline">
        <h1>2023中国AIGC与元宇宙创新创业创作大赛</h1>  
      </div>
      <div class="headlineEN">
        <p >2023CHINA  AIGC  AND  METAVERSE  INNOVATION  AND  ENTERPRENEURSHIP  CREATION  COMPETIOTION</p>
      </div>
      <div class="subtitle">
        <p >————华东师范大学专场</p>
      </div>   
    </div>
    <!-- <div class="container2">
      <div class="container2-1">
        <div class="announcement-header">
        <h2>赛事资讯</h2>
      </div>
      <hr class="announcement-divider">
      <div class="announcement-list">
        <div class="announcement-item">
          <span class="announcement-title">这是一条公告</span>
          <span class="announcement-date">2023-04-08</span>
        </div>
        <div class="announcement-item">
          <span class="announcement-title">这是另一条公告</span>
          <span class="announcement-date">2023-04-07</span>
        </div>
        <div class="announcement-item">
          <span class="announcement-title">这是另一条公告</span>
          <span class="announcement-date">2023-04-07</span>
        </div>
        <div class="announcement-item">
          <span class="announcement-title">这是另一条公告</span>
          <span class="announcement-date">2023-04-07</span>
        </div>
      </div>
      </div>
      <div class="container2-2">
        <div class="announcement-header">
        <h2>公告通知</h2>
      </div>
      <hr class="announcement-divider">
      <div class="announcement-list">
        <div class="announcement-item">
          <span class="announcement-title">这是一条公告</span>
          <span class="announcement-date">2023-04-08</span>
        </div>
        <div class="announcement-item">
          <span class="announcement-title">这是另一条公告</span>
          <span class="announcement-date">2023-04-07</span>
        </div>
        <div class="announcement-item">
          <span class="announcement-title">这是另一条公告</span>
          <span class="announcement-date">2023-04-07</span>
        </div>
        <div class="announcement-item">
          <span class="announcement-title">这是另一条公告</span>
          <span class="announcement-date">2023-04-07</span>
        </div>
      </div>
      </div>
    </div> -->
  </div>


  </template>
  
  <script>
  export default {
    name: "Home"
  };
  </script>
  
<style>
.home-container{
}
img {
  height: 23vh;
  width: 20vw;
  margin-bottom: 30px;
}
.headline-container{
  height: 60vh;
  margin-top: 20vh;
  margin-bottom: 20vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.headline{
  align-content: center;
  color: hsl(0, 0%, 100%);
  font-size:calc(100vw * 25 / 1920); 
  text-shadow: 0 0 10px #6d6f70;
  /* background: linear-gradient(to right, #8ea4fa, #c59ec5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}
.headlineEN{
  align-content: center;
  color: hsl(0, 0%, 100%);
  font-size: calc(100vw * 17 / 1920); 
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  text-shadow: 0 0 10px #5394d2;
}
.subtitle{
  margin-left: 30vh;
  color: hsl(0, 0%, 100%);
  font-size: calc(100vw * 30 / 1920); 
  text-shadow: 0 0 10px #5394d2;
}
h1,p{
  margin-top: 10px;
  margin-bottom: 10px;
}
.container2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10vh;
}
.container2-1 {
  margin-left: 10vw;
  width: 35vw;
  border: 1px solid #ccc;
  background-color: rgba(255,255,255,0.8);
  box-shadow: rgba(187, 187, 187, 1) 0 0px 20px -1px;
  -webkit-box-shadow: rgba(187, 187, 187, 1) 0 0px 20px -1px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}
.container2-2 {
  float: right;
  margin-right: 10vw;
  width: 35vw;
  border: 1px solid #ccc;
  background-color: rgba(255,255,255,0.8);
  box-shadow: rgba(187, 187, 187, 1) 0 0px 20px -1px;
  -webkit-box-shadow: rgba(187, 187, 187, 1) 0 0px 20px -1px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

.announcement-header {
  margin-top: 10px;
}

.announcement-header h2 {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  margin-left: 15px;
}

.announcement-divider {
  margin-top: 5px;
  margin-bottom: 5px;
  border-top: 1px solid #ccc;
}

.announcement-list {
  margin: 0;
  padding: 10px;
  list-style: none;
}

.announcement-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.announcement-item .announcement-title {
  width: 28vw;
  font-size: 16px;
  margin-left: 5px;
}

.announcement-item .announcement-date {
  width: auto;
  font-size: 14px;
  color: #999;
}

</style>
  