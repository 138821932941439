<template>
    <div class="modal">
      <div class="modal-content">
        <span class="modal-close" @click="$emit('close')">×</span> <!-- 右上角的关闭按钮 -->
        <p>作品展示暂未开放！</p>
      </div>
    </div>
  </template>
  
  <style scoped>
  .modal {
    position: fixed; /* 使用 fixed 定位方式，使其悬浮在所有内容的上方 */
    z-index: 9999; /* 使用较高的层级，确保其显示在其他内容的上方 */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 使用半透明的背景色，使其更突出 */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    width: 20vw;
    height: 20vh;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    position: relative;
  }
  .modal-close {
  position: absolute; /* 将关闭按钮设置为绝对定位 */
  top:5px;
  right:10px;
  font-size: 24px;
  cursor: pointer;
  text-align: end;
}
  </style>
  