<template>
    <div class="register-container"> 
      <form @submit.prevent="submitForm" class="register-form">
        <h2>注册</h2>
        <div class="form-group">
          <label class="login-lable">姓名:</label>
          <input  v-model="name" required>
        </div>
        <div class="form-group">
          <label class="login-lable">电子邮箱:</label>
          <input type="email" v-model="email" required>
        </div>
       <div class="form-group">
          <label class="login-lable">手机号:</label>
          <input type="tel" v-model="phone" pattern="^1[3|4|5|6|7|8|9][0-9]{9}$" required>
       </div>
        <div class="form-group">
          <label class="login-lable" prop="password">密码:</label>
          <input type="password" v-model="password" required>
        </div>
        <div class="form-group">
          <label class="login-lable">确认密码:</label>
          <input type="password" v-model="confirmPassword" required>
        </div>
        <div class="form-group">
          <label class="login-lable">学校:</label>
          <select v-model="school">
          <option value="华东师范大学">华东师范大学</option>
          </select>
        </div>
        <button type="submit">注册</button>
      </form>
    </div>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
      school: '华东师范大学',
    };
  },
  methods: {
    submitForm() {
      if(this.confirmPassword!=this.password){
        alert("两次输入的密码不一致！")
      }
      else{
        this.$axios
           .post('/register',this.$qs.stringify({
                name: this.name,
                password: this.password,
                phone:this.phone,
                email:this.email,
                school:this.school
            }))
            .then(response =>{
              console.log(response)
              if (response.data == "success") {
              alert("注册成功");
              this.$router.push('/login');
          } else {
              alert("注册失败:"+response.data);
          }
            }).catch(error => {
              console.log(error);
              alert('注册失败：请检查网络或重新尝试');
            })
        }
      }
  },
};
</script>

<style>
  .register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10vh;
    margin-bottom: 10vh;
  }
  
  .register-form {
    width: 50vw;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: rgba(255,255,255,0.9);
    box-shadow: rgb(132, 131, 131) 0 0px 20px -1px;
    -webkit-box-shadow: rgb(132, 131, 131) 0 0px 20px -1px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
  }
form {
  max-width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
  border-radius: 5px;
  background-color: rgba(255,255,255,0);
}
.form-group {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 1.5rem;
  width: 300px;
}

label {
  display: inline-block;
    /* width: auto; */
    text-align: left;
}

input,
select {
  padding:5px;
  font-size: 1rem;
  border: 2px solid #ddd;
  border-radius: 5px;
  margin-bottom: 0px;
  width: 300px;
  box-sizing: border-box;
}

button[type="submit"] {
  padding: 0.5rem 2rem;
  font-size: 1.2rem;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
}
</style>