// // import Vue from 'vue'
// import VueRouter from 'vue-router'

// import Login from '../view/Login.vue'

// // Vue.use(VueRouter)

// const routes = [
//   {
//     path: '/login',
//     name: 'Login',
//     component: Login
//   }
// ]

// const router = new VueRouter({
//   routes
// })

// export default router
 
import { createRouter, createWebHistory } from 'vue-router'
import Login from '../view/Login.vue'
import Register from '../view/Register.vue'
import Home from '../view/Home.vue'
import Info from '../view/Info.vue'
import ShowWorks from '../view/ShowWorks.vue'
import UploadWork from '../view/UploadWork.vue'
import MyWork from '../view/MyWork.vue'
import AllWork from '../view/AllWork.vue'
 
const routerHistory = createWebHistory()
 
 
const routes = [
    {
        path: '/',
        redirect: '/home' // 默认跳转到 /home 页面
      },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
      },
      {
        path: '/home',
        name: 'Home',
        component: Home
      },
      {
        path: '/info',
        name: 'Info',
        component: Info
      },
      {
        path: '/showWorks',
        name: 'ShowWorks',
        component: ShowWorks
      },
      {
        path: '/uploadWork',
        name: 'UploadWork',
        component: UploadWork
      },
      {
        path: '/mywork',
        name: 'MyWork',
        component: MyWork
      },
      {
        path: '/allwork',
        name: 'AllWork',
        component: AllWork
      },
      
  ]

const router = createRouter({
    history: routerHistory,
 routes
})
 
export default router
