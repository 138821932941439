<template>
  <div class="container">
    <NavBar :isLoggedIn="isLoggedIn" :name="name"></NavBar>
    <router-view></router-view>
    <footer style="justify-content: center;">
      <p style="font-size: 10px;">华东师范大学经管学部MBA元宇宙学社 设计学院元宇宙研习社 计算机学院AI俱乐部 天目空间   联系邮箱：1695620896@qq.com</p>
    </footer>
  </div>
</template>

<script>
import NavBar from './components/navigationBar.vue'
import { computed } from 'vue'

export default {
  name: 'App',
  components: {
    NavBar,
  },
  setup() {
    const name = computed(() => JSON.parse(localStorage.getItem('name')))
    console.log(name)
    return {
      name
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.container {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: fixed;
  overflow:auto;
  background-size: cover;
  background-position: center;
  background-image: url(./assets/background.jpg);
}
footer{
  display: flex;
  align-items: center;
  background-color: #000000;
  color: #cfcfcf;
  height: 50px;
  padding: 0 2vw;
  position: relative;
  justify-content: center;
}

</style>


