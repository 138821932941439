<template>
    <div class="contain">
    <div class="my-container">
      <div class="headbar">
        <div
          class="tab"
          :class="{ active: activeTab === 'all' }"
          @click="(activeTab = 'all') && (detailname = '')"
        >
          全部作品
        </div>
        <div
        class="tab"
        :class="{ active: activeTab === 'detailwork' }"
        @click="activeTab = 'detailwork'"
      >
        {{detailname}}
      </div>
      </div>
      <div class="content">
        <div v-if="activeTab === 'all'">
          <div class="showfile1">
            <p v-if="workshow==''">暂时还没有作品！</p>
            <div class="showitem" v-for="(items, index) in workshow" :key="index" @click="showdetail(index)">
              <img :src="items.workImg" alt="" class="imgshow"/>
              <label class="workname">{{ items.workname }}</label>
              <label class="author">{{ items.author }}</label>
            </div>
          </div>
        </div>
        <div v-if="activeTab === 'detailwork'">
          <div class="content2">
            <p class="p-item">参赛人姓名：{{ works[detailindex].name }}</p>
            <p class="p-item">学院：{{ works[detailindex].college }}</p>
            <p class="p-item">专业：{{ works[detailindex].major }}</p>
            <p class="p-item">班级：{{ works[detailindex].classes }}</p>
            <p class="p-item">手机号：{{ works[detailindex].phone }}</p>
            <p class="p-item">微信号：{{ works[detailindex].wxnumber }}</p>
            <p class="p-item">邮箱：{{ works[detailindex].email }}</p>
            <p class="p-item">指导教师：{{ works[detailindex].teacher }}</p>
            <p class="p-item">类别：{{ works[detailindex].category }}</p>
            <p class="p-item">子类别：{{ works[detailindex].subcategory }}</p>
            <p class="p-item">作品名称：{{ works[detailindex].workname }}</p>
            <p class="p-item">设计说明：{{ works[detailindex].description }}</p>
            <p class="p-item">作品：</p>
            <div class="showfile2" v-for="(url, index) in workImgs[detailindex]" :key="index">
              <img :src="url" :id="'img-' + index" alt="" class="imgfile" @click="showImage('img-' + index)">
            </div>
  
            <p class="p-item">AI工具名称：{{ works[detailindex].toolname }}</p>
            <p class="p-item">生成过程说明：{{ works[detailindex].process }}</p>
            <p class="p-item" v-if="works[detailindex].processtype=='图生图'">生成过程原图：</p>
            <div v-if="works[detailindex].processtype=='图生图'">
              <div class="showfile2" v-for="(url, index) in processImgs[detailindex]" :key="index">
                <img :src="url" :id="'process-' + index" alt="" class="imgfile" @click="showImage('process-' + index)">
              </div>
            </div>
            
            <p class="p-item">创作过程记录：</p>
            <div class="showfile2" v-for="(url, index) in recordImgs[detailindex]" :key="index">
              <img :src="url" :id="'record-' + index" alt="" class="imgfile" @click="showImage('record-' + index)">
            </div>
            <div class="showfile2" v-for="(url, index) in recordVideos[detailindex]" :key="index">
              <video :src="url" alt="" class="imgfile"></video>
              <button class="rawimg"><a :href="url" target="_blank">查看原视频</a></button>
            </div>
          </div> 
        </div>
      </div>
    </div>
  </div>
  </template>
    
<script>
//   import { computed } from "vue";
import Viewer from 'viewerjs'
import 'viewerjs/dist/viewer.css'
  export default {
    // setup() {
    //   const userid = computed(() => Number(localStorage.getItem("userid")));
    //   const name = computed(() => JSON.parse(localStorage.getItem('name')))
    //   const phone = computed(() => JSON.parse(localStorage.getItem("phone")));
    //   const email = computed(() => JSON.parse(localStorage.getItem("email")));
    //   const school = computed(() => JSON.parse(localStorage.getItem("school")));
    //   console.log(name,phone)
    //   return {
    //     userid,
    //     name,
    //     phone,
    //     email,
    //     school
    //   };
    // },
    data() {
      return {
        viewer: null,
        activeTab: "all", // 默认显示全部作品选项卡
        workshow: [],
        detailindex:'',
        detailname:'',
        works: [
          {
            id: "",
            userid: "",
            name: "",
            studentid: "",
            college: "",
            major: "",
            classes: "",
            phone: "",
            wxnumber: "",
            email: "",
            teacher: "",
            category: "",
            subcategory: "",
            workname: "",
            description: "",
            workurls: "",
            toolname: "",
            processtype: "",
            process: "",
            processurls: "",
            recordurls: "",
          },
        ],
        category: "",
        subcategory: "",
        workfiles: [],
        processfiles: [],
        recordfiles: [],
        workImgs: [],
        processImgs: [],
        recordImgs: [],
        recordVideos: [],
      };
    },
    created() {
      // 调用后端接口获取用户上传的作品信息
      this.$axios
        .get("/getAllWork", {})
        .then((response) => {
          console.log(response.data);
          this.works = response.data;
          this.workfiles = this.works.map((work) => JSON.parse(work.workurls));
          this.processfiles = this.works.map((work) =>
            JSON.parse(work.processurls)
          );
          this.recordfiles = this.works.map((work) =>
            JSON.parse(work.recordurls)
          );
          this.getImgs().then(() => {
            for (let i = 0; i < this.works.length; i++) {
              const newWork = {
                workImg: this.workImgs[i][0],
                workname: this.works[i].workname,
                author: this.works[i].name
              };
              this.workshow.push(newWork);
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    methods: {
      showdetail(index){
        this.detailindex = index
        this.detailname = this.works[index].workname
        this.activeTab = 'detailwork'
        console.log("点击了第" + (index + 1) + "项");
      },
      getImgs() {
        return new Promise((resolve) => {
          for (let j = 0; j < this.works.length; j++) {
            this.workImgs.push([]);
            this.processImgs.push([]);
            this.recordImgs.push([]);
            this.recordVideos.push([]);
            for (let i = 0; i < this.workfiles[j].length; i++) {
              const url = "https://" + decodeURIComponent(this.workfiles[j][i]);
              this.workImgs[j].push(url);
            }
            for (let i = 0; i < this.processfiles[j].length; i++) {
              const url =
                "https://" + decodeURIComponent(this.processfiles[j][i]);
              this.processImgs[j].push(url);
            }
            for (let i = 0; i < this.recordfiles[j].length; i++) {
              const url = "https://" + decodeURIComponent(this.recordfiles[j][i]);
              const suffix = url.split('.').pop(); // 获取后缀
              if (['jpg', 'jpeg', 'png', 'gif'].includes(suffix.toLowerCase())) {
                this.recordImgs[j].push(url); // 图片格式，push到img数组里
              } else if (['mp4', 'avi', 'mov'].includes(suffix.toLowerCase())) {
                this.recordVideos[j].push(url); // 视频格式，push到video数组里
              }
            }
          }
          resolve();
        });
      },
    showImage(id) {
  // 销毁之前的 Viewer 实例
  if (this.viewer) {
    this.viewer.destroy()
  }
  // 创建新的 Viewer 实例
  this.viewer = new Viewer(document.getElementById(id), {
    toolbar: {
      prev: false,
      next: false,
      rotateLeft: false,
      rotateRight: false,
      flipHorizontal: false,
      flipVertical: false,
    },
    url(image) {
      return image.src
    },
  })
  // 设置 Viewer 实例的图片集
  this.viewer.update() // 通知 Viewer 实例重新加载图片
  // 显示 Viewer 实例
  this.viewer.show()
}
    },
  };
  </script>
    
    <style>
  .p-item {
    color: black;
    margin-top: 30px;
    margin-bottom: 30px;
    min-width: 400px;
    width: 40vw;
    /* text-align: left; */
    word-break: normal;
    display: block;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: hidden;
  }
  .contain{
    min-height: calc(100% - 100px);
  }
  .my-container {
    width: 60vw;
    min-width: 500px;
    min-height: 60vh;
    margin: 10vh auto;
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
  }
  
  .headbar {
    width: 100%;
    border-radius: 5px;
    background-color: #f4f4f4;
    display: flex;
    flex-direction: row;
  }
  
  .tab {
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .tab.active {
    border-radius: 5px;
    background-color: #ddd;
  }

  .content {
    display: flex;
    
  }
  
  .content1 {
    width: 54vw;
    text-align: left;
    float: left;
    margin-left: 6vw;
  }
  
  .content2 {
    width: 50vw;
    text-align: left;
    float: left;
    margin-left: 10vw;
    margin-bottom: 5vh;
  }
  
  .showfile1 {
    width: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
    margin-right: 1.2vw;
    margin-left: 1.2vw;
    flex-wrap: wrap; /* 超过一行自动换行 */
    justify-content: flex-start;
  }
  .showfile2 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .showitem{
    display: flex;
    flex-direction: column;
    margin-right: 1.2vw;
    margin-left: 1.2vw;
    align-items: center;
    cursor: pointer;
  }
  .workname{
    width: 12vw;
    margin-bottom: 5px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .author{
    font-size: 13px;
    width: 12vw;
    margin-bottom: 20px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .imgshow {
    width: 12vw;
    height: 15vh;
    margin-bottom: 10px;
    object-fit: cover;
  }
  .imgfile {
    object-fit: cover;
    cursor: pointer;
  }
  .rawimg {
    width: 100px;
    height: 30px;
    background-color: #d7d7d7;
    margin-bottom: 30px;
  }
  </style>  