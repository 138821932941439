<template>
  <div>
    <form @submit.prevent="submitWork" class="upload-form">
      <h2 class="up-headline">比赛作品上传</h2>
      <div class="info-column">
        <div class="special-row">
          <label for="name" class="special-lable"
            ><span class="IsMust">*</span>参赛人姓名：</label
          >
          <input type="text" id="name" v-model="work.name" required />
          <label></label>
        </div>
        <span class="info"
          >若多人组队参加，都需填写个人信息，用;隔开，首位为负责人</span
        >
      </div>
      <div class="info-column">
        <div class="special-row">
          <label for="studentid"><span class="IsMust">*</span>学号：</label>
          <input type="text" id="studentid" v-model="work.studentid" required />
          <label></label>
        </div>
        <span class="info">须按顺序填写每位成员信息，用;隔开</span>
      </div>
      <div class="info-column">
        <div class="special-row">
          <label for="college"><span class="IsMust">*</span>学院：</label>
          <input type="text" id="college" v-model="work.college" required />
          <label></label>
        </div>
        <span class="info">须按顺序填写每位成员信息，用;隔开</span>
      </div>
      <div class="info-column">
        <div class="special-row">
          <label for="major"><span class="IsMust">*</span>专业：</label>
          <input type="text" id="major" v-model="work.major" required />
          <label></label>
        </div>
        <span class="info">须按顺序填写每位成员信息，用;隔开</span>
      </div>
      <div class="info-column">
        <div class="special-row">
          <label for="class"><span class="IsMust">*</span>班级：</label>
          <input type="text" id="classes" v-model="work.classes" required />
          <label></label>
        </div>
        <span class="info">须按顺序填写每位成员信息，用;隔开</span>
      </div>
      <div class="info-column">
        <div class="special-row">
          <label for="phone"><span class="IsMust">*</span>手机号：</label>
          <input
            type="text"
            id="phone"
            v-model="work.phone"
            pattern="^1[3|4|5|7|8|9][0-9]{9}$"
            required
          />
          <label></label>
        </div>
        <span class="info">只需要填写负责人</span>
      </div>
      <div class="info-column">
        <div class="special-row">
          <label for="wxnumber"><span class="IsMust">*</span>微信号：</label>
          <input type="text" id="wxnumber" v-model="work.wxnumber" required />
          <label></label>
        </div>
        <span class="info">只需要填写负责人</span>
      </div>
      <div class="info-column">
        <div class="special-row">
          <label for="email"><span class="IsMust">*</span>邮箱：</label>
          <input
            type="text"
            id="email"
            v-model="work.email"
            pattern="^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$"
            required
          />
          <label></label>
        </div>
        <span class="info">只需要填写负责人</span>
      </div>
      <div class="form-row">
        <label for="teacher">指导教师：</label>
        <input type="text" id="teacher" v-model="work.teacher" />
        <label></label>
      </div>
      <div class="form-row">
        <label for="category"><span class="IsMust">*</span>赛道类别：</label>
        <select v-model="work.category" class="upload-select" required>
          <option value="艺术类">艺术类</option>
          <option value="实用类（设计方向）">实用类（设计方向）</option>
        </select>
        <label></label>
      </div>
      <div class="form-row">
        <label for="subcategory"><span class="IsMust">*</span>子赛道：</label>
        <select v-model="work.subcategory" class="upload-select" required>
          <option v-if="category == ''" disabled>请先选择赛道！</option>
          <option v-if="work.category == '艺术类'" value="平面风格:二次元、像素、漫画等">
            平面风格:二次元、像素、漫画等
          </option>
          <option v-if="work.category == '艺术类'" value="现代风格:抽象、科幻、超现实等">
            现代风格:抽象、科幻、超现实等
          </option>
          <option v-if="work.category == '艺术类'" value="古典风格:油画、素描、水彩、版画等">
            古典风格:油画、素描、水彩、版画等
          </option>
          <option v-if="work.category == '艺术类'" value="国画风格:山水、花鸟、工笔等">
            国画风格:山水、花鸟、工笔等
          </option>
          <option v-if="work.category == '实用类（设计方向）'" value="时尚设计:服装、饰品等">
            时尚设计:服装、饰品等
          </option>
          <option v-if="work.category == '实用类（设计方向）'" value="视觉设计:平面、数码(可用图片序列方式提交)、动画(可用图片序列方式提交)等">
            视觉设计:平面、数码(可用图片序列方式提交)、动画(可用图片序列方式提交)等
          </option>
          <option v-if="work.category == '实用类（设计方向）'" value="环境设计:室内、景观、建筑等">
            环境设计:室内、景观、建筑等
          </option>
          <option v-if="work.category == '实用类（设计方向）'" value="产品设计:家具、载具、电器等">
            产品设计:家具、载具、电器等
          </option>
        </select>
        <label></label>
      </div>
      <div class="info-column">
        <div class="special-row">
          <label for="workname"><span class="IsMust">*</span>作品名称：</label>
          <input type="text" id="workname" v-model="work.workname" required />
          <label></label>
        </div>
        <span class="info"
          >若需要重新提交，请填写相同的作品名称，将取最新提交的版本参赛</span
        >
      </div>
      <div class="info-column">
        <div class="special-row2">
          <label for="description"
            ><span class="IsMust">*</span>设计说明：</label
          >
          <textarea
            id="description"
            v-model="work.description"
            rows="5"
            pattern=".{0,200}"
            required
          ></textarea>
          <label></label>
        </div>
        <span class="info">200 字以内，可结合赛事主题，描述灵感来源</span>
      </div>
      <div class="info-column">
        <div class="special-row">
          <label for="file-upload"><span class="IsMust">*</span>作品：</label>
          <input
            type="file"
            id="image"
            accept=".jpg,.jpeg,.png"
            @change="onWorkSelected"
            required
            multiple
            :max="10 * 1024 * 1024"
          />
          <label></label>
        </div>
        <span class="info">单张 10MB 以内,全部选择完成后请点击上传</span>
        <div class="delete-group" v-for="(file, index) in works" :key="index">
          <span>{{ file.name }}</span>
          <button @click="removeWork(index)">删除</button>
        </div>
        <div>
          <button @click.prevent="uploadFile()" v-if="works.length != 0" >上传</button>
        </div>
      </div>
      <div class="info-column">
        <div class="special-row">
          <label for="toolname"
            ><span class="IsMust">*</span>AI工具名称：</label
          >
          <input type="text" id="toolname" v-model="work.toolname" required />
          <label></label>
        </div>
        <span class="info">附工具链接</span>
      </div>
      <div class="info-column">
        <label><span class="IsMust">*</span>生成过程说明：</label>
        <div class="form-row">
          <label for="category"
            ><span class="IsMust">*</span>生成过程类型：</label
          >
          <select v-model="work.processtype" class="upload-select" required>
            <option value="文生图">文生图</option>
            <option value="图生图">图生图</option>
          </select>
          <label></label>
        </div>
        <div v-if="work.processtype == '文生图'" class="special-row2">
          <label for="process"><span class="IsMust">*</span>关键词：</label>
          <textarea
            id="process"
            v-model="work.process"
            rows="5"
            required
          ></textarea>
          <label></label>
        </div>
        <span v-if="work.processtype == '文生图'" class="info"
          >请给出关键词</span
        >
        <div v-if="work.processtype == '图生图'" class="special-row2">
          <label for="process"><span class="IsMust">*</span>关键词：</label>
          <textarea
            id="process"
            v-model="work.process"
            rows="5"
            required
          ></textarea>
          <label></label>
        </div>
        <div v-if="work.processtype == '图生图'" class="special-row2-img">
          <label for="process"><span class="IsMust">*</span>原图：</label>
          <input
            type="file"
            id="processimg"
            accept=".jpg,.jpeg,.png"
            @change="onProcessSelected"
            required
            multiple
          />
          <label></label>
        </div>
        <span v-if="work.processtype == '图生图'" class="info"
          >请给出关键词+原图,全部选择完成后请点击上传</span
        >
        <div
          class="delete-group"
          v-for="(file, index) in processfiles"
          :key="index"
        >
          <span>{{ file.name }}</span>
          <button @click="removeProcess(index)">删除</button>
        </div>
        <div>
          <button @click.prevent="uploadProcess()" v-if="processfiles.length != 0" >上传</button>
        </div>
      </div>
      <div class="info-column">
        <div class="special-row">
          <label for="record"
            ><span class="IsMust">*</span>创作过程记录：</label
          >
          <input
            type="file"
            id="record"
            accept=".jpg,.jpeg,.png,.mp4,.avi"
            @change="onRecordSelected"
            required
            multiple
            :max="100 * 1024 * 1024"
          />
          <label></label>
        </div>
        <span class="info">图片(至少三张)或视频（大小不超过100MB）,全部选择完成后请点击上传</span>
        <div class="delete-group" v-for="(file, index) in records" :key="index">
          <span>{{ file.name }}</span>
          <button @click="removeRecord(index)">删除</button>
        </div>
        <div>
          <button @click.prevent="uploadRecord()" v-if="records.length != 0" >上传</button>
        </div>
      </div>
      <div class="form-row">
        <button type="submit">提交</button>
      </div>
    </form>
    <modal v-if="isModalVisible" @close="hideModal"></modal>
  </div>
</template>

<script>
import { computed } from "vue";
import Modal from '../components/Modal2.vue';
import { v4 as uuid } from 'uuid';
export default {
  setup() {
    const userid = computed(() => Number(localStorage.getItem("userid")));
    return {
      userid,
    };
  },
  components: {
      Modal,
    },
  data() {
    return {
      isModalVisible: false,
      isUploadWorkFinished: false,
      isUploadRecordFinished: false,
      isUploadProcessFinished: false,
      works: [],
      records: [],
      processfiles: [],
      work: {
        userid: "",
        name: "",
        studentid: "",
        college: "",
        major: "",
        classes: "",
        phone: "",
        wxnumber: "",
        email: "",
        teacher: "",
        category: "",
        subcategory: "",
        workname: "",
        description: "",
        workurls: [],
        toolname: "",
        processtype: "",
        process: "",
        processurls: [],
        recordurls: [],
      },
    };
  },
  methods: {
    //上传作品图片
    // uploadFile() {
    //   const cos = this.$cos
    //   for (let i = 0; i < this.works.length; i++) {
    //     const work = this.works[i]
    //     // 拼接作品名称和用户 ID 作为图片对象的 Key
    //     const key = `work/${this.work.workname}_${this.work.userid}_${i}.jpg`
    //     cos.putObject({
    //       Bucket: 'aigc-static-1308722423',
    //       Region: 'ap-shanghai',
    //       Key: key,
    //       Body: work
    //     }, (err, data) => {
    //       if (err) {
    //       console.error('Error:', err)
    //     } else {
    //       console.log('Upload Result:', data)
    //       const url = data.Location
    //       // 将文件 URL 存储在数据库中
    //       this.work.workurls.push(url)
    //     }
    //     })
    //   }
    //   console.log(this.work.workurls)
    // },
    uploadFile() {
      this.work.workurls = [];
      //弹窗
      this.isModalVisible = true;
      const cos = this.$cos;
      const promises = [];
      for (let i = 0; i < this.works.length; i++) {
        const work = this.works[i];
        const key = `work/${uuid()}_${work.name}`;
        const promise = new Promise((resolve, reject) => {
          cos.putObject(
            {
              Bucket: "aigc-static-1308722423",
              Region: "ap-shanghai",
              Key: key,
              Body: work,
            },
            (err, data) => {
              if (err) {
                reject(err);
              } else {
                const url = data.Location;
                this.work.workurls.push(url);
                resolve();
              }
            }
          );
        });
        promises.push(promise);
      }
      Promise.all(promises)
        .then(() => {
          this.isModalVisible = false;
          this.isUploadWorkFinished = true;
          console.log(this.work.workurls);
          alert("上传成功!");
        })
        .catch((err) => {
          this.isModalVisible = false;
          this.isUploadWorkFinished = false;
          console.error("Error:", err);
          alert("上传失败，请重新尝试!");
        });
    },

    uploadRecord() {
      this.work.recordurls = [];
      //弹窗
      this.isModalVisible = true;
      const cos = this.$cos;
      const promises = [];
      for (let i = 0; i < this.records.length; i++) {
        const work = this.records[i];
        const key = `record/${uuid()}_${work.name}`;
        const promise = new Promise((resolve, reject) => {
          cos.putObject(
            {
              Bucket: "aigc-static-1308722423",
              Region: "ap-shanghai",
              Key: key,
              Body: work,
            },
            (err, data) => {
              if (err) {
                reject(err);
              } else {
                const url = data.Location;
                this.work.recordurls.push(url);
                resolve();
              }
            }
          );
        });
        promises.push(promise);
      }
      Promise.all(promises)
        .then(() => {
          this.isModalVisible = false;
          this.isUploadRecordFinished = true;
          console.log(this.work.recordurls);
          alert("上传成功!");
        })
        .catch((err) => {
          this.isModalVisible = false;
          this.isUploadRecordFinished = false;
          console.error("Error:", err);
          alert("上传失败，请重新尝试!");
        });
    },

    uploadProcess() {
      this.work.processurls = [];
      //弹窗
      this.isModalVisible = true;
      const cos = this.$cos;
      const promises = [];
      for (let i = 0; i < this.processfiles.length; i++) {
        const work = this.processfiles[i];
        const key = `process/${uuid()}_${work.name}`;
        const promise = new Promise((resolve, reject) => {
          cos.putObject(
            {
              Bucket: "aigc-static-1308722423",
              Region: "ap-shanghai",
              Key: key,
              Body: work,
            },
            (err, data) => {
              if (err) {
                reject(err);
              } else {
                const url = data.Location;
                this.work.processurls.push(url);
                resolve();
              }
            }
          );
        });
        promises.push(promise);
      }
      Promise.all(promises)
        .then(() => {
          this.isModalVisible = false;
          this.isUploadProcessFinished = true;
          console.log(this.work.processurls);
          alert("上传成功!");
        })
        .catch((err) => {
          this.isModalVisible = false;
          this.isUploadProcessFinished = false;
          console.error("Error:", err);
          alert("上传失败，请重新尝试!");
        });
    },



    submitWork() {
      if(!this.isUploadWorkFinished){
        alert("还未上传作品！")
      }else if(!this.isUploadRecordFinished){
        alert("还未上传创作过程记录！")
      }else if(!this.isUploadProcessFinished&&this.work.processtype=='图生图'){
        alert("还未上传生成过程说明！")
      }else{
        this.work.userid = this.userid;
        this.isModalVisible = true;
        // 然后将数据传到后端
        this.$axios.post(
          "/uploadWork",
          this.$qs.stringify({
            userid: this.work.userid,
            name: this.work.name,
            studentid: this.work.studentid,
            college: this.work.college,
            major: this.work.major,
            classes: this.work.classes,
            phone: this.work.phone,
            wxnumber: this.work.wxnumber,
            email: this.work.email,
            teacher: this.work.teacher,
            category: this.work.category,
            subcategory: this.work.subcategory,
            workname: this.work.workname,
            description: this.work.description,
            workurls: JSON.stringify(this.work.workurls),
            toolname: this.work.toolname,
            processtype: this.work.processtype,
            process: this.work.process,
            processurls: JSON.stringify(this.work.processurls),
            recordurls: JSON.stringify(this.work.recordurls),
          })
        ).then((response) => {
        console.log(response)
        if (response.data === "success") {
          this.isModalVisible = false;
          alert("上传成功");
          this.$router.push("/myWork");
        } else {
          this.isModalVisible = false;
          alert(`上传失败: ${response.data}`);
        }
        }).catch((error) => {
          this.isModalVisible = false;
          console.error(error);
          alert('接口调用出错');
      });
    }
    },
      

    onWorkSelected(event) {
      const newFiles = event.target.files;
      for (let i = 0; i < newFiles.length; i++) {
        this.works.push(newFiles[i]);
      }
    },
    removeWork(index) {
      this.works.splice(index, 1);
      // const input = document.getElementById('image');
      //待解决
      // input.value = null;
    },

    onProcessSelected(event) {
      const newFiles = event.target.files;
      for (let i = 0; i < newFiles.length; i++) {
        this.processfiles.push(newFiles[i]);
      }
    },
    removeProcess(index) {
      this.processfiles.splice(index, 1);
      // const input = document.getElementById('processimg');
      //待解决
      // input.value = null;
    },

    onRecordSelected(event) {
      const newFiles = event.target.files;
      for (let i = 0; i < newFiles.length; i++) {
        this.records.push(newFiles[i]);
      }
    },
    removeRecord(index) {
      this.records.splice(index, 1);
      // const input = document.getElementById('record');
      //待解决
      // input.value = null;
    },
  },
};
</script>
  
  <style>
.upload-form {
  margin: auto;
  width: 60vw;
  max-width: 60vw;
  margin-top: 10vh;
  color: #666;
  text-align: center;
  box-shadow: rgb(132, 131, 131) 0 0px 20px -1px0px -1px;
  -webkit-box-shadow: rgb(132, 131, 131) 0 0px 20px -1px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.9);
  margin-bottom: 10vh;
}
.up-headline {
  padding-top: 20px;
  color: #000000;
  padding-bottom: 5px;
  margin-bottom: 40px;
}
.form-row {
  margin-bottom: 30px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.special-row {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.special-row2 {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.special-row2-img {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}
.IsMust {
  width: auto;
  color: #f55c5c;
}
label {
  width: 120px;
  display: inline-block;
  text-align: right;
  color: #000000;
}
input[type="text"],
input[type="file"],
.upload-select {
  min-width: 400px;
  width: 27vw;
  height: 35px;
  padding: 5px;
  font-size: 16px;
  border-radius: 3px;
  border: 1px solid #ccc;
  background-color: rgba(255, 255, 255, 1);
}
textarea {
  min-width: 400px;
  width: 27vw;
  padding: 5px;
  font-size: 16px;
  border-radius: 3px;
  border: 1px solid #ccc;
  background-color: rgba(255, 255, 255, 1);
  resize: none;
  box-sizing: border-box;
}
.delete-group {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-left: 120px;
  margin-right: 120px;
}
span {
  width: 200px;
  text-align: start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.info-column {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  margin-bottom: 30px;
  font-size: 16px;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0);
}
.info {
  min-width: 400px;
  width: 27vw;
  text-align: left;
  margin-left: 120px;
  word-break: normal;
  display: block;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
}
button[type="submit"] {
  background-color: #0077c2;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 3px;
  border: none;
  margin-bottom: 20px;
}
</style>
  
  
  