<template>
  <div class="nav-bar">
    <div class="logo">
      <router-link to="/home">
        <img alt="logo" src="../assets/logo.png">
      </router-link>
    </div>
    <div class="nav-links">
      <a href="https://mp.weixin.qq.com/s/rZdMYxXdKx0iVdt7s98aqw" target="_blank">比赛介绍</a>
      <a @click="showModal">作品展示</a>
      <router-link v-if="isLoggedIn && (type == 1)" to="/uploadWork">上传作品</router-link>
      <router-link v-if="isLoggedIn && (type == 2)" to="/allwork">全部作品</router-link>
      <router-link v-if="!isLoggedIn" to="/login">登录</router-link>
      <router-link v-if="isLoggedIn && (type == 1)" to="/mywork">{{name}}</router-link>
      <a v-if="isLoggedIn" @click="logout">退出登录</a>
    </div>
    <modal v-if="isModalVisible" @close="hideModal"></modal>
  </div>
</template>

<script>
  import Modal from './Modal.vue';
  import { computed } from 'vue'
  export default {
    name: 'NavBar',
    components: {
      Modal,
    },
    setup() {
    const isLoggedIn = computed(() => JSON.parse(localStorage.getItem('name')) !== null)
    const name = computed(() => JSON.parse(localStorage.getItem('name')))
    const type = computed(() => Number(localStorage.getItem("type")))
    console.log(isLoggedIn,name,type)
    return {
      isLoggedIn,
      name,
      type
    }
  },
    data() {
      return {
        isModalVisible: false,
      };
    },
    methods: {
      logout(){
        this.isLoggedIn = false
        alert("退出登录成功！")
        localStorage.removeItem('name')
        localStorage.removeItem('type')
        window.location.replace('/home')
      },
      showModal() {
        this.isModalVisible = true;
      },
      hideModal() {
        this.isModalVisible = false;
      },
    },
  };
</script>

<style scoped>
.nav-bar {
  display: flex;
  align-items: center;
  background-color: #000000;
  color: #fff;
  height: 50px;
  padding: 0 2vw;
  position: relative;
}

.logo img {
  height: 60px;
  width: 100px;
  margin-bottom: 0px;
}

.nav-links {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;  
}

.nav-links a {
  margin-left: 3vw;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.nav-links a:hover {
  text-decoration: underline;
}
</style>
